import React from 'react';

import VerifyEmail from 'components/pages/sign-up/verify-email';
import MainLayout from 'layouts/main';

const VerifyEmailPage = () => (
  <MainLayout headerTheme="grey-5">
    <VerifyEmail />
  </MainLayout>
);

export default VerifyEmailPage;
