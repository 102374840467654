import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Footer from 'components/shared/footer';
import Header from 'components/shared/header';
import Link from 'components/shared/link';
import MobileMenu from 'components/shared/mobile-menu';
import SEO from 'components/shared/seo';

const MainLayout = ({ seo, headerTheme, children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleHeaderBurgerClick = () => setIsMobileMenuOpen(true);
  const handleMobileMenuOverlayOrCloseClick = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.querySelector('html').style = 'overflow: hidden';
      document.querySelector('body').style = 'overflow: hidden; touch-action: none';
    } else {
      document.querySelector('html').style = '';
      document.querySelector('body').style = '';
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      {seo && <SEO {...seo} />}
      <div className="flex flex-col min-h-screen">
        <Link to="https://www.browserless.io/blog/2023/12/06/browserless-2-0/" target="_blank">
          <div className="w-full flex justify-center bg-browserless-gradient font-semibold py-2">
            Browserless v2 is out 🚀! Read more about it here.
          </div>
        </Link>
        <Header theme={headerTheme} onBurgerClick={handleHeaderBurgerClick} />
        <main className="flex flex-col grow h-full">{children}</main>
        <Footer />
        <MobileMenu
          isOpen={isMobileMenuOpen}
          onOverlayOrCloseClick={handleMobileMenuOverlayOrCloseClick}
        />
      </div>
    </>
  );
};

MainLayout.propTypes = {
  seo: PropTypes.shape({}),
  pageContext: PropTypes.shape({}),
  headerTheme: PropTypes.oneOf(['primary-black', 'primary-white', 'grey-5']).isRequired,
  children: PropTypes.node.isRequired,
};

MainLayout.defaultProps = {
  seo: null,
  pageContext: null,
};

export const query = graphql`
  fragment wpPageSeo on WpPage {
    seo {
      title
      metaDesc
      metaKeywords
      metaRobotsNoindex
      canonical
      opengraphTitle
      opengraphDescription
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 630, width: 1200, formats: JPG)
          }
        }
      }
      opengraphUrl
    }
  }
  fragment wpPostSeo on WpPost {
    seo {
      title
      metaDesc
      metaKeywords
      metaRobotsNoindex
      canonical
      opengraphTitle
      opengraphDescription
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 630, width: 1200, formats: JPG)
          }
        }
      }
      opengraphUrl
    }
  }
`;

export default MainLayout;
