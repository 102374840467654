/* eslint-disable no-return-assign */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { paddings } from 'constants/spacing';
import { markEmailVerified } from 'utils/api';

/*
 * Small email verifier, sends over token, secret and email to our API to verify
 * `token` and `secret` are base64 encoded, and email is uri-encoded
 */
const VerifyEmail = () => {
  const [isBusy, setIsBusy] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const verifyEmail = ({ email, token, secret }) =>
    markEmailVerified({
      email,
      token,
      secret,
    })
      .then((res) => {
        if (res && res.setEmailVerification && res.setEmailVerification.isVerified) {
          setIsBusy(false);
          setSuccess(true);
        }
      })
      .catch((error) => setError(error.message));

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);

    const b64Token = searchParams.get('token');
    const b64Secret = searchParams.get('secret');
    const encodedEmail = searchParams.get('email');

    const errorMessage =
      !b64Token || !encodedEmail || !b64Secret
        ? 'Looks like we had trouble verifying your email. Contact us at support@browserless.io'
        : '';

    if (errorMessage) {
      setError(errorMessage);
      setIsBusy(false);
      return;
    }

    const token = atob(b64Token);
    const secret = atob(b64Secret);
    const email = decodeURIComponent(encodedEmail);
    verifyEmail({ email, token, secret });
  }, []);

  return (
    <section
      className={classNames('grow h-full pt-20 xl:pt-15 md:pt-10 bg-grey-5', paddings.bottom.lg)}
    >
      <Container className="flex flex-col items-center">
        <Heading className="text-center" tag="h1" size="xl" theme="primary-black">
          Verifying your email
        </Heading>
        <div className="items-center mt-10 sm:relative">
          {isBusy && (
            <div className="flex items-center justify-center">
              <div className="w-full px-8 pt-8 pb-6 border border-grey-10 bg-primary-white md:p-4">
                <div className="w-32 h-32 border-b-2 border-gray-900 rounded-full animate-spin" />
              </div>
            </div>
          )}
          {error && (
            <div className="px-4 py-3 text-red-700 bg-red-100 border border-red-400" role="alert">
              <strong className="font-bold">Oops!</strong>
              <span className="block sm:inline">
                Looks like we had trouble verifying your email, please try again.
              </span>
            </div>
          )}
          {success && (
            <div
              className="px-4 py-3 text-blue-700 bg-blue-200 border border-blue-500"
              role="alert"
            >
              <strong className="font-bold">Success!</strong>
              <span className="block sm:inline">
                You may close this tab and may go back to the sign-up tab.
              </span>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default VerifyEmail;
